const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
import '../css/app.scss';
import '../css/jquery.fancybox.min.css';
import './jquery.fancybox.min.js';

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

$(document).ready(function () {
    $('[data-toggle="popover"]').popover();

    $('.catalog-item').on('click', function (event) {
        if ($(this).next()) {
            $(this).next().animate({
                height: 'toggle',
                opacity: 'toggle'
            }, 'fast');
            $(this).parent().siblings().find('ul').animate({
                height: 0,
                opacity: 0
            }, 'fast', function () {
                $(this).hide();
                $(this).css('height', 'initial');
                $(this).css('width', 'initial');
                $(this).css('opacity', 1);
            });
        }
    });

    $('#archive-toggle').change(function () {
        $('[data-status="Archief"]').toggle();
    });

    let tableContent = "";
    $('#merge-technical-toggle').change(function () {

        if ($(this).is(':checked')) {
            tableContent = $('.technical table').html();
            var cellObjects = [];
            $('.technical table').find('tr').each(function (rowIndex, r) {
                var cells = $(this).find('th,td');
                var countCells = cells.length;

                for (var i = 2; i < cells.length; i++) {
                    var colspan = 2;
                    var cell = $(cells.get(i));
                    var cellNext = cell.next();
                    for (var j = 3; j < cells.length; j++) {
                        if (cell.text() === cellNext.text()) {
                            cellObjects.push(cellNext);
                            if (cell.attr('colspan') === 'undefined') {
                                cell.attr('colspan', 2);
                            } else {
                                cell.attr('colspan', colspan);
                            }
                            colspan = colspan + 1;
                            cellNext = cellNext.next();
                        } else {
                            break;
                        }
                    }
                }
            });
            for (var i = 0; i < cellObjects.length; i++) {
                cellObjects[i].remove();
            }
        } else {
            $('.technical table').html(tableContent);
        }
    });
});